<script setup lang="ts">
import { Message } from '@arco-design/web-vue'

const props = withDefaults(defineProps<{ text: string, title?: string, icon?: string }>(), { title: '点击复制', icon: 'icon-fuzhi' })

const { t } = useI18n()
const { copy, isSupported } = useClipboard({ legacy: true })
function copyClick() {
  if (!isSupported.value) {
    return Message.error('当前浏览器不支持复制，请手动复制')
  }

  copy(props.text)
  Message.success(t('复制成功'))
}
</script>

<template>
  <g-btn
    :title="title"
    :icon="`${icon} !text-20`"
    @click="copyClick"
  >
    <slot />
  </g-btn>
</template>
